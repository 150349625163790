import { GET_OPERATOR_DATA } from "src/actions/type";
import { apiRequest } from "src/async/apiUtils";

export const getOperators =
  (body, signal = null) =>
  async (dispatch) => {
    try {
      const {data} = await apiRequest("GET", `/operators/list`, {body}, {signal});

      if (data.status === 200) {
        dispatch({ type: GET_OPERATOR_DATA, payload: data.data });
        return data.data;
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };
