import Select from "src/components/Shared/Forms/Selects/Select";

const SiteColorItem = ({ color = "", type = "color", value = "", onChange, name, generalInputDisabled = false, dropdownDisabled = false }) => {
  const identifier = !value ? "px" : value.includes("px") ? "px" : "%";

  return (
    <div className="py-0 relative">
      <div className="text-black font-semibold text-xs mb-1 leading-none uppercase">{name}</div>
      <div className="flex items-center text-gray-800">
        {type === "color" && (
          <div className="w-8 h-6">
            <input
              className="uppercase"
              id={`color-picker-${name}`}
              type="color"
              value={color}
              onChange={(e) => onChange("#" + e.target.value.split("#")[1]?.toUpperCase())}
            />
          </div>
        )}
        {type === "color" ? (
          <>
            #
            <input
              value={color.split("#")[1]?.toUpperCase()}
              className="-ml-[10px] border-none rounded-md bg-transparent w-full h-8 sm:h-10 focus:border-none overflow-x-hidden"
              onChange={(e) => onChange("#" + e.target.value?.toUpperCase())}
            />
          </>
        ) : (
          <div className="flex items-center space-x-1">
            <input
              disabled={generalInputDisabled}
              value={value.replace(/%|px/g, "")}
              className="!border border-gray-300 rounded-md bg-transparent h-8 sm:h-10 w-full focus:border-none overflow-x-hidden text-sm px-1 py-1"
              onChange={(e) => {
                if (e.target.value && /^[0-9]+$/.test(e.target.value)) {
                  onChange(e.target.value + identifier);
                } else if (!e.target.value) {
                  onChange(e.target.value + identifier);
                }
              }}
            />
            <Select
              buttonClasses={"!h-10"}
              disabled={dropdownDisabled}
              selected={{ _id: identifier, name: identifier }}
              options={[
                { _id: "px", name: "px" },
                { _id: "%", name: "%" },
              ]}
              setSelected={(val) => {
                if (!value || ["%", "px"].includes(value.trim())) {
                  onChange(val._id);
                } else {
                  onChange(value.replace(/(\d+)(%|px)/, `$1${val._id}`));
                }
              }}
            />
            {/* <div className="text-[8px] text-black leading-tight">Use percentage or pixel (i.e. "%" or "px")</div> */}
          </div>
        )}
      </div>
    </div>
  );
};

export default SiteColorItem;
