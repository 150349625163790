import React from "react";
import { CalendarDaysIcon } from "@heroicons/react/24/outline";
import { formatDate } from "src/helpers/formatDate";

const UserDateTime = ({ data = null }) => {
  return (
    <>
      <button
        type="button"
        className="flex h-10 items-center justify-center space-x-2 rounded-md bg-gray-200/30 px-3">
        <CalendarDaysIcon className="h-8 w-8 stroke-1 text-gray-500" />
        <span className="flex flex-col text-left leading-tight">
          <span className="text-sm font-semibold text-gray-700">{formatDate(data?.last_login, "dddd, Do MMMM")}</span>
          <span className="text-[10px] font-semibold text-gray-400">{formatDate(data?.last_login, "h:mm a")}</span>
        </span>
      </button>
    </>
  );
};

export default UserDateTime;
