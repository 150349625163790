import { useEffect, useRef } from "react";
import { isEqual } from "lodash";

function useDeepCompareEffect(callback, dependencies) {
  const previousDeps = useRef();

  if (!isEqual(previousDeps.current, dependencies)) {
    previousDeps.current = dependencies;
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(callback, [previousDeps.current]);
}

export default useDeepCompareEffect;
