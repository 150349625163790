import { IdentificationIcon, ShareIcon, WrenchScrewdriverIcon } from "@heroicons/react/24/outline";
import Tabs from "src/components/Shared/Tabs/Tabs";
import { H1 } from "src/components/Shared/Text/Headers";
import SSOCustomizations from "src/components/SSO/SSOCustomizations";
import SSOList from "src/components/SSO/SSOList";
import SSOServiceMetadata from "src/components/SSO/SSOServiceMetadata";

const List = () => {
  const tabs = [
    { name: "SSO Providers", icon: IdentificationIcon, component: <SSOList /> },
    { name: "Customizations", icon: WrenchScrewdriverIcon, component: <SSOCustomizations /> },
    { name: "Service Provide Metadata", icon: ShareIcon, component: <SSOServiceMetadata /> },
  ];

  return (
    <>
      <H1>SSO</H1>
      <Tabs tabs={tabs} />
    </>
  );
};

export default List;
