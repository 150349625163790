import React from "react";
import { connect } from "react-redux";
import { classNames } from "src/helpers/classNames";

const AuthHeader = ({ title, switchTo, site }) => {
  return (
    <div className={classNames("sm:mx-auto sm:w-full sm:max-w-md pb-6 rounded-md md:min-w-[430px]", site.background_logo ? "bg-white":"")}>
      <h2 className={"mt-6 text-center text-3xl font-extrabold text-gray-900"}>{title}</h2>
      <div className="mt-2 text-center text-regular font-medium text-gray-600 ">
        {switchTo.startText}
        <a
          href={switchTo.location}
          target="_blank"
          rel="noreferrer"
          className="hover:underline pl-1 text-highlightColor">
          {switchTo.text}
        </a>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, null)(AuthHeader);

