import { connect } from "react-redux";
import { BoltIcon, Cog8ToothIcon, DocumentDuplicateIcon, EnvelopeIcon, KeyIcon, RectangleStackIcon } from "@heroicons/react/24/outline";
import React, { useEffect, useState } from "react";
import ImageWithEdit from "src/components/Shared/ImageWithEdit";
import UserWorkspaceList from "src/components/Users/Edit/Workspaces/UserWorkspaceList";
import { classNames } from "src/helpers/classNames";
import Tabs from "src/components/Shared/Tabs/Tabs";
import UserActivityList from "src/components/Users/Edit/Activity/UserActivityList";

const UserBodyContent = ({ data = null, ...props }) => {
  const [tabs, setTabs] = useState([]);

  useEffect(() => {
    setTabs([
      {
        name: "Workspaces",
        icon: RectangleStackIcon,
        component: <UserWorkspaceList userDetails={data} />,
        hidden: props?.user?.type === "admin" ? false : true,
      },
      { name: "Activity", icon: BoltIcon, component: <UserActivityList userDetails={data} /> },
      { name: "Settings", icon: Cog8ToothIcon, component: <div>Hello Settings</div> },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?._id, props?.user?.type]);

  return (
    <div className="">
      <div className="main_item-background relative px-8 pb-6 pt-8">
        <div className="mb-4 flex items-center space-x-4">
          {data?.type === "admin" && (
            <div className="flex h-8 items-center justify-center gap-2 rounded border border-amber-500 bg-amber-50 px-2 text-amber-500">
              <KeyIcon className="h-5 w-5 stroke-2" /> Admin
            </div>
          )}
          <div className={classNames("flex h-8 items-center justify-center gap-2 rounded border px-2", data?.active_status ? "border-green-500 bg-green-50 text-green-500" : "border-red-500 bg-red-50 text-red-500")}>{data?.active_status ? "Active" : "Deactivated"}</div>
        </div>
        <div className="mb-5 flex items-center space-x-4">
          <ImageWithEdit
            image={data?.image}
            name={data?.name}
            initialsClasses="font-bold"
          />
          <h1 className="text-4xl font-bold text-gray-900">{data?.name}</h1>
        </div>
        <div className="flex items-center gap-5">
          <div className="flex w-40 items-center gap-2 font-semibold text-gray-600">
            <EnvelopeIcon className="h-5 w-5 stroke-2" /> Email
          </div>
          <div className="relative flex w-full max-w-md items-center gap-5">
            <input
              type={"email"}
              value={data?.email || ""}
              className="h-10 w-full rounded border-0 bg-gray-200/40 px-3 py-0 pr-10 font-semibold !ring-0"
              onChange={() => {}}
            />
            <button
              type="button"
              className="absolute right-1 top-1 z-10 flex h-8 w-8 items-center justify-center rounded border border-gray-200 bg-white text-gray-500 shadow-sm">
              <DocumentDuplicateIcon className="h-5 w-5 stroke-2" />
            </button>
          </div>
          <div className="relative">
            <button
              type="button"
              className="flex h-10 items-center justify-center px-2 py-0 font-semibold text-gray-500 underline">
              Edit
            </button>
          </div>
        </div>
      </div>
      <div className="px-8 pb-4">
        <Tabs tabs={tabs} />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    site: state.site,
  };
};

export default connect(mapStateToProps, {})(UserBodyContent);
