import { connect } from "react-redux";
import Section from "src/components/Shared/Containers/Section";
import DomainSection from "./DomainSection";

const DomainSettings = ({ siteData }) => {
  return (
    <>
      <div className="flex flex-col gap-y-4">
        {siteData?.manage_subdomains && (
          <Section>
            <DomainSection />
          </Section>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    siteData: state.site,
  };
};

export default connect(mapStateToProps, {})(DomainSettings);
