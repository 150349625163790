import { dispatcher } from "src/actions/helpers/dispatcher";
import { GET_SSO_DATA, DELETE_SSO_DATA } from "src/actions/type";
import { apiRequest } from "src/async/apiUtils";

export const addSSO = (body, signal = null, url = "/sso/add", httpType = "POST", dispatchType = GET_SSO_DATA) => dispatcher({ body }, signal, url, httpType, dispatchType);
export const getSSOData = (body, signal = null, url = "/sso/list", httpType = "GET", dispatchType = GET_SSO_DATA) => dispatcher({ body }, signal, url, httpType, dispatchType);
export const getXmlData = (body, signal = null, url = "/sso/details/url", httpType = "POST", dispatchType = GET_SSO_DATA) => dispatcher({ body }, signal, url, httpType, dispatchType);
export const getDefaultSSOData = (body, signal = null, url = "/sso/default", httpType = "POST", dispatchType = GET_SSO_DATA) => dispatcher({ body }, signal, url, httpType, dispatchType);
export const getSso = (body, signal = null, url = "/sso/details", httpType = "POST", dispatchType = GET_SSO_DATA) => dispatcher({ body }, signal, url, httpType, dispatchType);
export const updateSso = (body, signal = null, url = "/sso/edit", httpType = "POST", dispatchType = GET_SSO_DATA) => dispatcher({ body }, signal, url, httpType, dispatchType);

export const updateSsoWorkspaceGroupSettings = (body, signal = null) => async (dispatch) => {
  try {
    const {data} = await apiRequest("POST", `/sso/workspace-group/settings/edit`, {body}, {signal});

    if (data.status === 200) {
      return data.message;
    } else {
      throw new Error(data.message);
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

export const updateSsoCustomFieldSettings = (body, signal = null) => async (dispatch) => {
  try {
    const {data} = await apiRequest("POST", `/sso/custom-field/settings/edit`, {body}, {signal});

    if (data.status === 200) {
      return data.message;
    } else {
      throw new Error(data.message);
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

export const deleteSso = (id, signal) => async (dispatch) => {
  try {
    const {data} = await apiRequest("POST", `/sso/remove`, { body: {id} }, {signal});

    if (data.status === 200) {
      dispatch({ type: DELETE_SSO_DATA, payload: id });
      return data.message;
    } else {
      throw new Error(data.message);
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

export const makeDefault = (body, signal = null, url = "/sso/set-default", httpType = "POST") => dispatcher({ body }, signal, url, httpType);
export const ssoLoginData = (body, signal = null, url = "/sso-url/details", httpType = "POST", dispatchType = GET_SSO_DATA) => dispatcher({ body }, signal, url, httpType, dispatchType);
