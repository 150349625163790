import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";

const Search = ({ label, labelClasses, searchClasses, keyword, setKeyword, placeholder }) => {
  return (
    <div className={"w-full max-w-md flex flex-col " + searchClasses}>
      {label && <label className={"block text-sm font-medium text-gray-700 " + labelClasses}>{label}</label>}
      <div className="relative rounded-md shadow-sm w-full flex">
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none" aria-hidden="true">
          <MagnifyingGlassIcon className="mr-3 h-4 text-gray-400" aria-hidden="true" />
        </div>
        <input autoComplete="off" type="text" name="search" id="search" value={keyword} className="h-[47px] md:h-[40px] 2xl:h-[47px] block w-full pl-9 border-gray-300 text-sm rounded-md focus:ring-0 focus:border-highlightColor" placeholder={placeholder} onChange={(e) => setKeyword(e.target.value)} />
      </div>
    </div>
  );
};

export default Search;
