import TableBody from "src/components/Shared/Table/TableBody";
import TableBodyLoader from "src/components/Shared/Table/TableBodyLoader";
import { AnimatePresence } from "framer-motion";
import Preloader from "src/components/Shared/LoadingAnimations/Preloader";
import { classNames } from "src/helpers/classNames";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

const Table = ({ children, tableHeader, dataExists = false, loaded = false, colSpan = 0, listDraggableEnabled = false, listData = [], listComponent = () => {}, onDragEnd = () => {}, onDragStart = () => {} }) => {
  return (
    <AnimatePresence>
      <div className="relative flex flex-col">
        <div className="w-full">
          <div className="rounded-md shadow-md ring-1 ring-black ring-opacity-5 md:rounded-lg md:rounded-b-lg">
            <table className="w-full table-auto divide-y divide-gray-300">
              {tableHeader}
              {listDraggableEnabled ? (
                <DragDropContext
                  onDragEnd={onDragEnd}
                  onBeforeDragStart={onDragStart}>
                  <Droppable droppableId="droppable">
                    {(provided) => (
                      <TableBody
                        droppableProps={provided.droppableProps}
                        innerRef={provided.innerRef}>
                        {listData.map((item, index) => (
                          <Draggable
                            key={item._id || item.id}
                            draggableId={item._id || item.id}
                            index={index}>
                            {(provided, snapshot) =>
                              listComponent({
                                item,
                                provided: provided,
                              })
                            }
                          </Draggable>
                        ))}
                        {provided.placeholder}
                        <TableBodyLoader
                          dataExists={dataExists}
                          loaded={loaded}
                          colSpan={colSpan}
                        />
                      </TableBody>
                    )}
                  </Droppable>
                </DragDropContext>
              ) : (
                <TableBody>
                  {dataExists && children}
                  <TableBodyLoader
                    dataExists={dataExists}
                    loaded={loaded}
                    colSpan={colSpan}
                  />
                </TableBody>
              )}
            </table>
          </div>
        </div>
        <div className={classNames("absolute -bottom-3.5 right-0 h-8 transition-all duration-500 ease-out sm:-right-6", dataExists && !loaded ? "opacity-100" : "opacity-0")}>
          <Preloader
            className="h-[40px]"
            circleDimension="5"
            size="sm"
          />
        </div>
      </div>
    </AnimatePresence>
  );
};

export default Table;
