const TableBody = ({ children, droppableProps = {}, innerRef = null }) => {
  return (
    <tbody
      className="relative w-full divide-y divide-gray-200"
      ref={innerRef}
      {...droppableProps}>
      {children}
    </tbody>
  );
};

export default TableBody;
