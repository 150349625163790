import Modal from "src/components/Shared/Modal";

const WorkspacesIntegrationPageListModal = ({ isOpen, pages = [], onCancel = () => {} }) => {
  return (
    <Modal
      title="Credential"
      secondaryTitle={`Page List`}
      isOpen={isOpen}
      onCancel={onCancel}
      defaultOptions={{
        onSuccessButtonVisible: false,
      }}
      defaultStyles={{
        overFlowYVisible: false,
      }}>
      <div className="relative w-full space-y-4">
        {pages?.length > 0 &&
          pages.map((page, index) => {
            return (
              <div
                key={index}
                className="relative flex w-full flex-col space-y-4 rounded-md border border-gray-50 bg-white px-3 py-3 shadow md:space-y-0 lg:h-12 lg:flex-row lg:px-4 lg:py-1">
                <div className="flex pr-10 md:pr-[200px] lg:w-full lg:pr-0">{page?.name}</div>
              </div>
            );
          })}
      </div>
    </Modal>
  );
};

export default WorkspacesIntegrationPageListModal;
