import React, { useEffect, useState } from "react";
import { ChevronLeftIcon, ChevronRightIcon, EllipsisHorizontalIcon } from "@heroicons/react/24/outline";
import { classNames } from "src/helpers/classNames";

const HorizontalEllipsis = () => (
  <button
    type="button"
    className="flex h-8 w-8 items-center justify-center rounded-full bg-transparent bg-white p-0 font-bold text-gray-600 ">
    <EllipsisHorizontalIcon className="h-5 w-5 stroke-2" />
  </button>
);

const Pagination = ({ offset = 0, limit = 10, count = 1, onChange = () => {} }) => {
  const [tabCount, setTabCount] = useState(1);
  useEffect(() => {
    if (count && limit) {
      setTabCount(Math.ceil(count / limit));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, count]);

  return (
    <>
      <div className="flex items-center justify-center gap-4 pt-4">
        <button
          type="button"
          onClick={() => {
            if (offset - 1 >= 0) {
              onChange({ limit, offset: offset - 1 });
            }
          }}
          className={classNames("flex h-10 w-10 items-center justify-center rounded-full border border-gray-200 bg-white p-0 font-semibold text-gray-700 shadow-sm", offset > 0 ? "" : "cursor-not-allowed opacity-40")}>
          <ChevronLeftIcon className="h-5 w-5 stroke-2" />
        </button>

        <div className="flex items-center justify-center gap-2">
          <button
            type="button"
            onClick={() => onChange({ limit, offset: 0 })}
            className={classNames(
              "flex h-8 w-8 items-center justify-center rounded-full p-0 font-bold ",
              tabCount === 1 ? "cursor-not-allowed opacity-40" : offset === 0 ? "bg-highlightColor text-white hover:bg-highlightColor hover:text-white" : "bg-white text-gray-600 hover:bg-gray-50",
            )}>
            1
          </button>
          {tabCount > 1 && (
            <>
              {offset > 2 ? <HorizontalEllipsis /> : null}
              {offset >= 2 ? (
                <button
                  type="button"
                  onClick={() => onChange({ limit, offset: offset - 1 })}
                  className="flex h-8 w-8 items-center justify-center rounded-full bg-transparent bg-white p-0 font-bold text-gray-600 hover:bg-gray-100">
                  {offset}
                </button>
              ) : null}
              {0 !== offset && offset + 1 !== tabCount ? (
                <button
                  type="button"
                  className="flex h-8 w-8 items-center justify-center rounded-full bg-highlightColor p-0 font-bold text-white hover:bg-highlightColor hover:text-white">
                  {offset + 1}
                </button>
              ) : null}
              {offset < tabCount - 2 ? (
                <button
                  type="button"
                  onClick={() => onChange({ limit, offset: offset + 1 })}
                  className="flex h-8 w-8 items-center justify-center rounded-full bg-transparent bg-white p-0 font-bold text-gray-600 hover:bg-gray-100">
                  {offset + 2}
                </button>
              ) : null}
              {offset < tabCount - 3 ? <HorizontalEllipsis /> : null}
            </>
          )}
          {tabCount > 1 && (
            <button
              type="button"
              onClick={() => onChange({ limit, offset: tabCount - 1 })}
              className={classNames("flex h-8 w-8 items-center justify-center rounded-full p-0 font-bold", offset + 1 === tabCount ? "bg-highlightColor text-white hover:bg-highlightColor hover:text-white" : "bg-white text-gray-600 hover:bg-gray-50")}>
              {tabCount}
            </button>
          )}
        </div>
        <button
          type="button"
          onClick={() => {
            if (offset + 1 < tabCount) {
              onChange({ limit, offset: offset + 1 });
            }
          }}
          className={classNames("flex h-10 w-10 items-center justify-center rounded-full border border-gray-200 bg-white p-0 font-semibold text-gray-700 shadow-sm", offset + 1 < tabCount ? "" : "cursor-not-allowed opacity-40")}>
          <ChevronRightIcon className="h-5 w-5 stroke-2" />
        </button>
      </div>
    </>
  );
};

export default Pagination;
