import { combineReducers } from "redux";
import authReducer from "src/reducers/authReducer";
import userReducer from "src/reducers/userReducer";
import pageReducer from "src/reducers/pageReducer";
import siteSettingsReducer from "src/reducers/siteReducer";
import operatorReducer from "src/reducers/operatorReducer";
import userDetailsReducer from "src/reducers/userDetailsReducer";
import workspaceDetailsReducer from "src/reducers/workspaceDetailsReducer";
import twoFactorReducer from "src/reducers/twoFactorReducer";
import collapseReducer from "src/reducers/collapseReducer";
import menuReducer from "src/reducers/menuReducer";
import groupReducer from "src/reducers/groupReducer";
import workspaceReducer from "src/reducers/workspaceReducer";
import emailerReducer from "src/reducers/emailerReducer";
import ssoReducer from "src/reducers/ssoReducer";
import activityReducer from "src/reducers/activityReducer";
import siteCredentialReducer from "src/reducers/siteCredentialReducer";

export default combineReducers({
  auth: authReducer,
  users: userReducer,
  pages: pageReducer,
  site: siteSettingsReducer,
  operators: operatorReducer,
  userDetails: userDetailsReducer,
  workspaceDetails: workspaceDetailsReducer,
  twoFactorDetails: twoFactorReducer,
  collapse: collapseReducer,
  menus: menuReducer,
  sso: ssoReducer,
  activity: activityReducer,
  groups: groupReducer,
  workspaces: workspaceReducer,
  emailers: emailerReducer,
  pageCredentialOptions: siteCredentialReducer,
});
