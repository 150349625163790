import { Listbox, Transition } from "@headlessui/react";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { CheckIcon, ChevronUpDownIcon, ChevronDownIcon } from "@heroicons/react/24/solid";
import { Fragment, useEffect, useState } from "react";
import { classNames } from "src/helpers/classNames";
import Input from "src/components/Shared/Forms/Inputs/Input";

const Select = ({ options, disabled = false, label = "", secondaryLabel = "", setSelected, selected = { id: -1, name: " " }, required = false, selectType = "", dropdownClasses = "", inline = false, wrapperClass = "", buttonClasses = "", searchableFields = [] }) => {
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [keyword, setKeyword] = useState("");
  let selectedVal = selected.name === "None selected" && required ? "" : "";

  useEffect(() => {
    setFilteredOptions(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  useEffect(() => {
    if (searchableFields.length > 0) {
      let updatedFilteredOptions = options.filter((option) => {
        let keywordMatched = false;
        searchableFields.forEach((searchableField) => {
          if (option?.object[searchableField]?.toLowerCase().includes(keyword.toLowerCase())) {
            keywordMatched = true;
          }
        });
        return keywordMatched;
      });
      setFilteredOptions(updatedFilteredOptions);
    }
  }, [keyword, options, searchableFields]);

  return (
    <Listbox
      disabled={disabled}
      className=""
      value={selected}
      onChange={setSelected}>
      {({ open }) => (
        <div className={classNames(inline ? "relative rounded-md border px-0 pt-2 focus-within:border-highlightColor md:pt-1 2xl:pt-2" : "flex w-full min-w-[80px] flex-col", inline && disabled ? "border-gray-100 bg-gray-50" : "border-gray-300", wrapperClass)}>
          {(label || secondaryLabel) && (
            <div className={classNames(inline ? "m-0 pl-3 text-xs font-semibold uppercase leading-none text-gray-400 md:pl-2 md:text-[10px] 2xl:pl-3" : "mb-1 block text-sm font-medium text-gray-700")}>
              {label && <span className="pr-2 leading-none">{label}</span>}
              {secondaryLabel && <span className=" leading-none text-gray-300">{secondaryLabel}</span>}
            </div>
          )}
          <div className={"relative " + (selectType !== "label" ? "h-10" : "mb-1", inline ? "h-8 md:h-7 2xl:h-8" : "")}>
            {selectType === "label" ? (
              <Listbox.Button
                disabled={disabled}
                className={classNames(
                  "relative w-full text-left text-sm text-gray-700",
                  inline ? "h-8 rounded-none border-none px-3 py-0 shadow-none md:h-7 md:px-2 2xl:h-8 2xl:px-4" : `flex items-center focus:outline-none  ${disabled ? " cursor-not-allowed text-gray-50/50" : " cursor-default"}` + selectedVal,
                  buttonClasses,
                )}>
                <span className={classNames("block truncate", inline ? "pt-px" : "")}>{selected.name}</span>
                <span className="pointer-events-none inset-y-0 flex items-center pl-2">
                  <ChevronDownIcon
                    className="h-3 w-3 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>
            ) : (
              <Listbox.Button
                disabled={disabled}
                className={classNames(
                  "relative w-full pl-3 text-left text-gray-700 sm:text-sm",
                  inline
                    ? "flex h-8 items-center rounded-none border-none px-3 py-0 shadow-none md:h-7 md:px-2 2xl:h-8 2xl:px-3"
                    : `h-[47px] rounded-md border border-gray-300 bg-white py-2 pr-10 focus:border-highlightColor focus:outline-none md:h-[40px] 2xl:h-[47px]  ${disabled ? " cursor-not-allowed bg-gray-50/50" : " cursor-default"}` + selectedVal,
                  buttonClasses,
                )}>
                <span className={classNames("flex items-center truncate leading-none", inline ? "pt-0.5" : "min-h-[17.5px]")}>{selected.name}</span>
                <span className={classNames("pointer-events-none  absolute right-0 flex items-center pr-2", inline ? "-top-1" : "inset-y-0")}>
                  {!disabled && (
                    <ChevronUpDownIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  )}
                </span>
              </Listbox.Button>
            )}

            {!disabled && (
              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0">
                <Listbox.Options className={classNames("absolute z-30 mt-1 max-h-60 w-full overflow-y-auto rounded-md bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm", dropdownClasses)}>
                  {searchableFields.length > 0 && (
                    <div className="sticky left-0 top-0 z-20 mb-2  w-full bg-white p-1.5">
                      <Input
                        type="text"
                        autoComplete="off"
                        name="search"
                        id="search"
                        value={keyword}
                        className="block w-full rounded-md border-gray-300 pl-9 text-sm focus:border-highlightColor focus:ring-0"
                        inputClassNames={"h-10 block w-full sm:text-sm border-gray-300 rounded-md focus:ring-0 focus:border-highlightColor !pl-8"}
                        onKeyDown={(e) => e.stopPropagation()}
                        onChange={(e) => setKeyword(e.target.value)}
                      />
                      <div
                        className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
                        aria-hidden="true">
                        <MagnifyingGlassIcon
                          className="mr-3 h-4 text-gray-400"
                          aria-hidden="true"
                        />
                      </div>
                    </div>
                  )}
                  <div className="py-1">
                    {filteredOptions.map((option, i) =>
                      selectType === "label" ? (
                        <Listbox.Option
                          key={i}
                          className={({ active }) => classNames(active ? "bg-gray-50 text-highlightColor" : "text-gray-900", "relative cursor-default select-none text-xs")}
                          value={option}>
                          {({ selected, active }) => (
                            <>
                              <div className={classNames(selected ? "font-semibold" : "font-normal", "block truncate px-2 py-2")}>{option.name}</div>
                            </>
                          )}
                        </Listbox.Option>
                      ) : (
                        <Listbox.Option
                          key={i}
                          className={({ active }) => classNames(active ? "bg-gray-50 text-highlightColor" : "text-gray-900", "relative cursor-default select-none py-2 pl-4 pr-4 text-sm")}
                          value={option}>
                          {({ selected, active }) => (
                            <>
                              <span className={classNames(selected ? "font-semibold" : "font-normal", "block truncate pl-3")}>{option.name}</span>

                              {selected ? (
                                <span className={classNames(active ? "text-white" : "text-highlightColor", "absolute inset-y-0 left-0 flex items-center pl-1.5")}>
                                  <CheckIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                      ),
                    )}
                  </div>
                </Listbox.Options>
              </Transition>
            )}
          </div>
        </div>
      )}
    </Listbox>
  );
};

export default Select;
