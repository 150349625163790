import { PhotoIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { baseUrl, publicImageStorage } from "src/config/host";
import { classNames } from "src/helpers/classNames";
import { formatName } from "src/helpers/formatName";
import { convertToBase64, getS3ImageDetails } from "src/helpers/image";

const ImageWithEdit = ({ image = "", setImage, name = "", size = "md", buttonContent = null, showButtonContent = false, initialsClasses = null }) => {
  const sizeStyles = {
    xs: { size: "6", location: "4" },
    sm: { size: "12", location: "4" },
    md: { size: "16", location: "4" },
    lg: { size: "20", location: "3" },
    xl: { size: "24", location: "3" },
    xxl: { size: "28", location: "3" },
    xxxl: { size: "36", location: "3" },
  };
  const [isImgAvail, setIsImgAvail] = useState(true);
  const updateImage = (e) => {
    const allowedExts = ["jpg", "jpeg", "png", "bmp", "gif", "svg", "webp", "avif"];

    if (e.target.files[0]) {
      const ext = e.target.files[0].type.split("/")[1];

      if (!allowedExts.includes(ext)) {
        toast.error("Please upload a valid image.");
      } else {
        convertToBase64(e.target.files[0], setImage);
      }
    }
  };

  const [s3Image, setS3Image] = useState({
    loading: false,
    data: null,
  });

  useEffect(() => {
    if (image) {
      if (!image.includes("data:image/") && !image.includes(publicImageStorage)) {
        const loadS3Image = async () => {
          setS3Image((data) => ({ ...data, loading: true }));
          const res = await getS3ImageDetails(image);
          if (res.status === 200) {
            setS3Image({ loading: false, data: res.data });
          } else {
            setS3Image((data) => {
              return { ...data, loading: false };
            });
          }
        };
        loadS3Image();
      } else {
        setS3Image({ loading: false, data: image });
      }
    }
  }, [image]);

  const imageInitials = <div className="flex h-full w-full items-center justify-center rounded-full bg-gray-200 text-2xl font-medium uppercase text-gray-900">{formatName(name)}</div>;

  return (
    <div className="flex items-center space-x-4">
      <div className={classNames("relative hidden flex-shrink-0 sm:block", `h-${sizeStyles[size].size} w-${sizeStyles[size].size}`)}>
        <div className="group h-full w-full overflow-hidden rounded-full">
          {isImgAvail || (image && s3Image.data && !s3Image.loading) ? (
            <img
              className="h-full w-full object-cover"
              src={s3Image.data || baseUrl + image}
              alt="profile"
              onError={() => setIsImgAvail(false)}
            />
          ) : (
            <div className={"h-full w-full " + initialsClasses}>{imageInitials}</div>
          )}

          <div>
            <label
              htmlFor="photo-edit"
              className={classNames(
                "group absolute z-20 cursor-pointer transition-all duration-75",
                showButtonContent ? "" : "opacity-0 group-hover:opacity-80",
                buttonContent ? `-top-${sizeStyles[size].size} left-${sizeStyles[size].size}` : `h-7 w-7 rounded-full bg-highlightColor -top-${sizeStyles[size].location} -right-${sizeStyles[size].location}`,
              )}>
              {buttonContent ? buttonContent : <PhotoIcon className="h-full w-full p-1 text-white" />}
            </label>
            <input
              id="photo-edit"
              type="file"
              className="hidden"
              onChange={updateImage}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageWithEdit;
