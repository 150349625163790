import { connect } from "react-redux";
import { EyeIcon, UserPlusIcon } from "@heroicons/react/24/outline";
import React, { useState } from "react";
import Button from "src/components/Shared/Buttons/Button";
import Search from "src/components/Shared/Search";
import MultiColumnLayout from "src/components/Shared/MultiColumnLayout/MultiColumnLayout";
import UserDateTime from "src/components/Users/List/UserDateTime";
import UserItems from "src/components/Users/List/UserItems";
import UserBodyContent from "src/components/Users/List/UserBodyContent";
import UserAddModal from "src/components/Users/Modal/UserAddModal";
import useGetListAndSelectItem from "src/hooks/useGetListAndSelectItem";

const Users2 = ({ filterUserType = [], ...props }) => {
  const [addModalIsOpen, setAddModalIsOpen] = useState(false);

  const { lists, meta, isLoaded, limit, offset, setOffset, keyword, setKeyword, selectedItem, setSelectedItem, handleOnPaginationChange } = useGetListAndSelectItem({
    url: `/users`,
    paginationData: { limit: 10, offset: 0 },
    additionalQuery: { type: String(filterUserType) },
  });

  const headerActions = [
    { _id: 1, name: "Workspaces", type: "", hasIcon: false, icon: "" },
    { _id: 2, name: "Activity", type: "", hasIcon: false, icon: "" },
    { _id: 3, name: "Settings", type: "", hasIcon: false, icon: "" },
    { _id: 4, name: "", type: "hr", hasIcon: false, icon: "" },
    { _id: 5, name: "Impersonate", type: "", hasIcon: true, icon: EyeIcon },
  ];

  return (
    <>
      <MultiColumnLayout
        title={"User List"}
        subTitle={`Total of ${meta?.count || 0} users`}
        asideLoaded={isLoaded}
        aside={lists}
        paginationData={{ meta, limit, offset }}
        handleOnPaginationChange={handleOnPaginationChange}
        itemEntry={(item) => (
          <UserItems
            data={item}
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
          />
        )}
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
        mainHeaderContent={
          <>
            <div className="w-96">
              <Search
                keyword={keyword}
                setKeyword={(val) => {
                  setOffset(0);
                  setKeyword(val);
                }}
                placeholder={"Search"}
                inline={true}
              />
            </div>
            <div className="relative">
              <Button
                version="secondary"
                onClick={() => setAddModalIsOpen(true)}>
                <UserPlusIcon className="h-5 w-5" />
                Add user
              </Button>
            </div>
          </>
        }
        bodyHeaderAdditionalContent={selectedItem?.last_login && <UserDateTime data={selectedItem} />}
        bodyHeaderActions={headerActions}
        bodyContent={<UserBodyContent data={selectedItem} />}
      />
      {/* Add Modal */}
      <UserAddModal
        isOpen={addModalIsOpen}
        onCancel={() => setAddModalIsOpen(false)}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    site: state.site,
  };
};

export default connect(mapStateToProps, {})(Users2);
