import React from "react";
import { KeyIcon } from "@heroicons/react/24/outline";
import UserProfile from "src/components/Users/UserProfile";
import { previousDateInString } from "src/helpers/dateHelper";
import { classNames } from "src/helpers/classNames";

const UserItems = ({ data = null, selectedItem = null, setSelectedItem = () => {} }) => {
  return (
    <>
      <div
        className={classNames(
          "group relative overflow-hidden rounded-md border bg-white transition-all duration-200 focus-within:ring-2 focus-within:ring-inset focus-within:ring-highlightColor hover:bg-gray-100 hover:text-highlightColor",
          selectedItem?._id === data?._id ? "ring-2 ring-inset ring-highlightColor " : ""
        )}>
        <button
          className={classNames("relative flex w-full gap-x-2 px-3 py-1.5 focus:outline-none items-center", data.active_status? "opacity-100":"opacity-30",)}
          onClick={() => setSelectedItem(data)}>
          <div className="h-8 w-8 overflow-hidden rounded-full">
            <UserProfile
              user={data}
              size="sm"
              className="!bg-gray-400/60 transition-all !text-white"
            />
          </div>
          <div className="max-w-[240px] items-start overflow-hidden">
            <p className="w-full truncate text-left text-sm font-semibold text-gray-900">{data.name}</p>
            <p className="-mt-[3px] w-full truncate text-left text-sm text-gray-400">{data.email}</p>
          </div>
          <div className="absolute right-1 top-2 flex gap-x-2">
            {data.type === "admin" && (
              <div className="flex h-6 w-6 items-center justify-center rounded bg-amber-100 text-sm font-semibold transition-all duration-200 group-hover:bg-white">
                <KeyIcon className="h-4 w-4 stroke-2 text-amber-500" />
              </div>
            )}
            <time
              dateTime={data.created_at}
              className="flex h-6 items-center justify-center whitespace-nowrap rounded bg-gray-100 px-2 text-xs font-semibold text-gray-400 transition-all duration-200 group-hover:bg-white">
              {previousDateInString(data.created_at)}
            </time>
          </div>
        </button>
      </div>
    </>
  );
};

export default UserItems;
