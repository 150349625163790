import { useState } from "react";
import Modal from "src/components/Shared/Modal";

const UserAddModal = ({ isOpen, onCancel = () => {}, onSuccess = () => {} }) => {
  const [isDisabled] = useState(false);
  return (
    <>
      <Modal
        title="User"
        secondaryTitle={`Add`}
        isOpen={isOpen}
        onCancel={onCancel}
        onSuccess={onSuccess}
        isLoading={isDisabled}>
        <div className="relative mb-5 flex w-full flex-col gap-y-5"></div>
      </Modal>
    </>
  );
};

export default UserAddModal;
