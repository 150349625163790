import { dispatcher } from "src/actions/helpers/dispatcher";
import { GET_USERS, GET_USER, GET_USER_DETAILS, CLEAR_USER_DETAILS, REMOVE_USER, LOGGED_IN, GET_GROUP_DATA, REMOVE_GROUP, ADD_GROUP, EDIT_GROUP } from "src/actions/type";
import { apiRequest } from "src/async/apiUtils";
import { getAuthorizedUser } from "./auth";

export const addUser = (body) => async (dispatch) => {
  try {
    const {data} = await apiRequest("POST", `/users/add`, {body});

    if (data.status === 200) {
      return data.message;
    } else {
      throw new Error(data.message);
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

export const manageUserRole = (body) => async (dispatch) => {
  try {
    const {data} = await apiRequest("POST", `/users/manage-type`, {body});

    if (data.status === 200) {
      return data.message;
    } else {
      throw new Error(data.message);
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getGroupData =
  (body, signal = null) =>
  async (dispatch) => {
    try {
      const {data} = await apiRequest("POST", `/groups/list`, {body}, {signal});

      if (data.status === 200) {
        dispatch({ type: GET_GROUP_DATA, payload: data.data });
        return data;
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };

export const addGroup = (body) => async (dispatch) => {
  try {
    let url = `/groups`;
    if (body?.defaultWorkspaceId) {
      url = `/workspaces/:workspace_id/groups`
    }
    const params = {workspace_id: body?.defaultWorkspaceId};
    const {data} = await apiRequest("POST", url, {body, params});

    if (data.status === 200) {
      if (data.data) {
        dispatch({ type: ADD_GROUP, payload: data.data });
      }

      return data;
    } else {
      throw new Error(data.message);
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

export const editGroupData = (body) => async (dispatch) => {
  try {
    const {data} = await apiRequest("PUT", `/groups `, {body});

    if (data.status === 200) {
      dispatch({ type: EDIT_GROUP, payload: data.data });
      return data;
    } else {
      throw new Error(data.message);
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

export const deleteGroupData = (id) => async (dispatch) => {
  try {
    const {data} = await apiRequest("POST", `/groups/remove `, { body: {id} });

    if (data.status === 200) {
      dispatch({ type: REMOVE_GROUP, payload: id });
      return data;
    } else {
      throw new Error(data.message);
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

export const uploadUsersByCSV = (body) => async (dispatch) => {
  try {
    // const resData = await apiRequest("POST", `/users/csv-user-upload?uploadType=api`, {body}); // Test for Domo dataset
    const {data: resData} = await apiRequest("POST", `/users/csv-user-upload?uploadType=manual`, {body}); // Use for CSV

    if (resData.status === 200) {
      return resData.message;
    } else {
      throw new Error(resData.message);
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

export const updateUser =
  (body, formData = false) =>
  async (dispatch, getState) => {
    try {
      let data;
      if (formData) {
        data = await apiRequest("POST", `/users/edit`, {body}, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        });
      } else {
        data = await apiRequest("POST", `/users/edit`, {body});
      }
      data = data?.data;
      if (data.status === 200) {
        const state = getState();

        if (state.auth.user?._id === data.data?._id) {
          getAuthorizedUser()
        }

        return data.message;
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };

export const updateUserPageAccess = (body) => async (dispatch, getState) => {
  try {
    const {data} = await apiRequest("POST", `users/edit-page-access`, {body});

    if (data.status === 200) {
      const state = getState();

      if (state.auth.user?._id === data.data?._id) {
        dispatch({ type: LOGGED_IN, payload: data.data });
      }

      return data.message;
    } else {
      throw new Error(data.message);
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

export const manageUserStatus = (user) => async (dispatch, getState) => {
  try {
    const state = getState();
    const findUser = Object.values(state.users).find((userData) => userData._id === user._id);
    if (findUser && !findUser.default_user) {
      if (state.site.api_state === "DOMO_DATASET") {
        return;
      }
    }

    const {data} = await apiRequest("POST", `/users/manage-status`, { body: {id: user._id} });

    if (data.status === 200) {
      dispatch({ type: GET_USER, payload: { ...user, active_status: !user.active_status } });
      return data.message;
    } else {
      throw new Error(data.message);
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getMenuUserList = (payload, signal = null, url = "/users", httpType = "GET", dispatchType = GET_USERS, onSuccess = (data) => data) => dispatcher(payload, signal, url, httpType, dispatchType, onSuccess);

export const getUsers =
  (body, signal = null) =>
  async (dispatch) => {
    try {
      const {data} = await apiRequest("GET", `/users/list`, {body}, {signal});

      if (data.status === 200) {
        dispatch({ type: GET_USERS, payload: data.data });
        return data.meta.count;
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };

export const getUserDetails =
  (body, signal = null) =>
  async (dispatch) => {
    try {
      const {data} = await apiRequest("POST", `/users/details`, {body}, {signal});

      if (data.status === 200) {
        dispatch({ type: GET_USER_DETAILS, payload: data.data });
        return data.message;
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };

export const clearUserDetails =
  (body = null) =>
  (dispatch) =>
    dispatch({
      type: CLEAR_USER_DETAILS,
      payload: {},
    });

export const removeUserPermission = (id) => async (dispatch) => {
  try {
    const {data} = await apiRequest("POST", `/users/permissions/remove`, { body: {id} });

    if (data.status === 200) {
      dispatch({ type: "EMPTY", payload: {} });
      return data.message;
    } else {
      throw new Error(data.message);
    }
  } catch (error) {
    return { status: 400, message: error.message };
  }
};

export const changePassword = (body) => async (dispatch) => {
  try {
    const {data: resData} = await apiRequest("POST", `/users/change-password`, {body});

    if (resData.status === 200) {
      return resData.message;
    } else {
      throw new Error(resData.message);
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

export const deleteUser = (body) => async (dispatch) => {
  try {
    const {data: resData} = await apiRequest("POST", `/users/remove`, {body});

    if (resData.status === 200) {
      dispatch({ type: REMOVE_USER, payload: body.id });
      return resData.message;
    } else {
      throw new Error(resData.message);
    }
  } catch (error) {
    throw new Error("Something went wrong.");
  }
};
