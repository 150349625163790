import { GET_MENU_DATA } from "src/actions/type";
import { apiRequest } from "src/async/apiUtils";

export const getMenuData =
  (body, signal = null) =>
  async (dispatch) => {
    try {
      const { data: resData } = await apiRequest("POST", `/menu/list`, {body}, {signal});
      if (resData.status === 200) {
        dispatch({ type: GET_MENU_DATA, payload: resData.data });
      } else {
        throw new Error(resData.message);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };
