import React from "react";
import EmailTemplateSettings from "./EmailTemplateSettings";
import SmtpSettings from "./SmtpSettings";

const SiteEmailManager = ({ workspaceId = null }) => {
  return (
    <div className="grid gap-y-4">
      {!workspaceId && <EmailTemplateSettings />}
      <SmtpSettings workspaceId={workspaceId} />
    </div>
  );
};

export default SiteEmailManager;
