import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/20/solid";
import React, { useState } from "react";
import Pagination from "src/components/Shared/MultiColumnLayout/MultiColumnLayoutComponents/Pagination";
import TopNavigationButton from "src/components/Shared/MultiColumnLayout/MultiColumnLayoutComponents/TopNavigationButton";
import { classNames } from "src/helpers/classNames";
import { ArrowSmallLeftIcon } from "@heroicons/react/24/outline";
import FullScreenButton from "src/components/Shared/MultiColumnLayout/MultiColumnLayoutComponents/FullScreenButton";
import SlideOver from "src/components/Shared/SlideOver";
import { H1 } from "../Text/Headers";
import NoData from "../NoData/NoData";
import Preloader from "../LoadingAnimations/Preloader";
import HeaderAction from "./MultiColumnLayoutComponents/HeaderAction";

export default function MultiColumnLayout({
  title = null,
  subTitle = null,
  asideLoaded = false,
  aside = [],
  paginationEnabled = true,
  paginationData = null,
  handleOnPaginationChange = () => {},
  itemEntry = null,
  selectedItem = {},
  setSelectedItem = () => {},
  mainHeaderContent = null,
  bodyHeaderAdditionalContent = null,
  bodyHeaderActions = [],
  bodyContent = null,
}) {
  const [fullScreen, setFullScreen] = useState(false);

  const nextPrevious = (from = "") => {
    const index = aside?.findIndex((item) => item?._id === selectedItem?._id);
    if (index !== -1) {
      if (from === "next" && aside?.length > index + 1) setSelectedItem(aside[index + 1]);
      if (from === "previous" && index - 1 >= 0) setSelectedItem(aside[index - 1]);
    }
  };

  return (
    <div>
      {title && (
        <H1>
          <div>
            {title}
            {subTitle && <div className="text-base font-normal text-gray-400">{subTitle}</div>}
          </div>
        </H1>
      )}
      <div className="flex h-[calc(100vh-170px)] flex-col gap-y-3">
        <header className="hidden h-16 w-full space-x-4 border-b border-gray-100 pb-4 lg:flex lg:min-w-0 lg:items-center">{mainHeaderContent}</header>

        {/* Bottom section */}
        <div className={classNames("flex h-full min-h-0 flex-1", !fullScreen ? "gap-4" : "")}>
          {/* ITEM LIST*/}
          <div className={classNames("flex-shrink-0 rounded-lg", !fullScreen ? "border-2 border-dashed border-gray-300 px-3 py-4" : "")}>
            <div className={classNames("hidden h-full max-h-[2000px] w-72 overflow-hidden transition-all duration-300 ease-in-out sm:block lg:w-80 xl:w-96", fullScreen ? "sm:max-w-[0px]" : "")}>
              <div className="relative flex h-full max-h-[calc(100%-4rem)] flex-col overflow-hidden rounded-md after:absolute after:bottom-0 after:left-0 after:h-6 after:w-full after:bg-gradient-to-t after:from-white after:content-['']">
                {asideLoaded ? (
                  aside?.length > 0 ? (
                    <nav className="relative h-full min-h-0 flex-1 overflow-y-auto">
                      <div className="scrollbar grid max-h-full w-full gap-y-2 overflow-y-scroll scroll-smooth border-gray-200 pr-2">
                        {aside.map((item) => (
                          <React.Fragment key={item._id}>{itemEntry(item)}</React.Fragment>
                        ))}
                      </div>
                    </nav>
                  ) : (
                    <NoData />
                  )
                ) : (
                  <Preloader color="text-gray-400 fill-gray-700" />
                )}
              </div>
              {paginationEnabled && aside?.length > 0 && (
                <Pagination
                  count={paginationData?.meta?.count || 0}
                  limit={paginationData?.limit}
                  offset={paginationData?.offset}
                  onChange={handleOnPaginationChange}
                />
              )}
            </div>
          </div>
          {/* SINGLE ITEM */}
          <main className="h-full min-w-0 flex-1 flex-shrink flex-grow xl:flex">
            <section className="scrollbar flex min-h-[calc(100vh-240px)] w-full min-w-0 overflow-auto scroll-smooth rounded-md border border-gray-200 pr-1 shadow">
              <div className="mb-2 flex w-full min-w-0 flex-col">
                <div className="h-13 flex flex-col justify-center border-b border-gray-100">
                  <div className="px-4 sm:px-4">
                    <div className="flex justify-between py-3">
                      {/* Left buttons */}
                      <nav
                        aria-label="Pagination"
                        className="flex items-center gap-x-2 sm:gap-x-3">
                        <div className="hidden p-1 sm:block">
                          <FullScreenButton onClick={() => setFullScreen(!fullScreen)} />
                        </div>
                        <div className="block p-1 sm:hidden">
                          <TopNavigationButton
                            item={{
                              text: "Back",
                              icon: ArrowSmallLeftIcon,
                              onClick: () => {
                                setFullScreen(!fullScreen);
                              },
                            }}
                          />
                        </div>
                        {selectedItem && aside?.length > 1 && (
                          <div className="hidden gap-x-2 sm:flex">
                            <TopNavigationButton
                              item={{
                                text: "Previous",
                                icon: ChevronUpIcon,
                                onClick: () => {
                                  nextPrevious("previous");
                                },
                              }}
                            />
                            <TopNavigationButton
                              item={{
                                text: "Next",
                                icon: ChevronDownIcon,
                                onClick: () => {
                                  nextPrevious("next");
                                },
                              }}
                            />
                          </div>
                        )}
                      </nav>
                      {/* Right buttons */}
                      <div className="flex space-x-4">
                        {bodyHeaderAdditionalContent}
                        {selectedItem && bodyHeaderActions && bodyHeaderActions?.length > 0 && (
                          <HeaderAction
                            data={bodyHeaderActions}
                            xPosition="right"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="relative rounded-b-md bg-white">{asideLoaded ? selectedItem ? bodyContent : <NoData /> : <Preloader color="text-gray-400 fill-gray-700" />}</div>
              </div>
            </section>
          </main>
        </div>
        <SlideOver />
      </div>
    </div>
  );
}
